@import url('https://fonts.googleapis.com/css2?family=Jersey+10&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0px;
}


body {
  background-color: white;
  color: black;
  margin: 0;
  font-family: "Jersey 10", sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (prefers-color-scheme: dark) {
    background-color: black;
    color: white;
  }

  @media (prefers-color-scheme: light) {
    background-color: white;
    color: black;
  }

}

a {
  color: lightcoral;

}


.jersey-34{
  font-size: 34;
}

.jersey-19{
  font-size: 19;
}

h1 {
  font-size: 34px;
  font-weight: 400;
}

h2 {
  font-weight: 400;
  font-size: 34px;
  margin: 10px 0;
  
}
h3 {
  font-weight: 400;
  font-size: 24px;
  margin: 10px 0;
}
article{
  font-size: 19px;
}

i{
  font-size:1.0em;
}
