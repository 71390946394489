.formpage {

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // @media screen and (max-width: 410px){

    //     width: 100%;
    //     font-size: 20px;
        
    // }
 
    &__element {
        border-bottom: 0px solid #000; /* Change color as needed */
        padding: 10px 0; /* Add some vertical padding */
        font-size: 28px;
        @media screen and (max-width: 600px){
            font-size: 22px;
        }
        @media screen and (max-width: 410px){
            font-size: 18px;
        }
        
        &-input {
            width:435px;
            margin:auto;
            background-color: rgb(0,0,0,0);
            border: none;
            border-bottom: 1px solid #000;
            font-family: "Jersey 10", sans-serif;
            font-size: 28px;
            &:focus {
                outline: none;
            }
            @media (prefers-color-scheme: light) {
                border-bottom: 1px solid #000;
            }
            @media (prefers-color-scheme: dark) {
                border-bottom: 1px solid #555;
            }
            @media screen and (max-width: 600px){
                width: 295px;
            }
            @media screen and (max-width: 410px){
                width: 230px;
            }
        }
        &-textarea {
            margin-left: auto;
            width:400px;
            height: 200px;
            background-color: rgb(0,0,0,0);
            border:1px solid #000;
            font-family: "Jersey 10", sans-serif;
            font-size: 28px;

            &:focus {
                outline: none;
            }
            @media (prefers-color-scheme: light) {
                border: 1px solid #000;
                color: #000;
            }
            @media (prefers-color-scheme: dark) {
                border: 1px solid #555;
                color: #fff;
            }
            @media screen and (max-width: 600px){
                width: 375px;
                height: 150px;
                margin: 0;
                padding: 0;
            }
            @media screen and (max-width: 410px){
                width: 301px;
                height: 150px;
                
            }
        }
        &-button {
            margin-top: 15px;
            margin-left: 330px;
            width: 180px;
            height: 50px;
            background-color: #000;
            color: #fff;
            font-family: "Jersey 10", sans-serif;
            font-size: 22px;
            border: none;
            cursor: pointer;
            &:hover {
                background-color: #f00;
            }
            @media (prefers-color-scheme: light) {
                background-color: black;
            }
            @media (prefers-color-scheme: dark) {
                border: 1px solid #888;
                background-color: #555;
            }
            @media screen and (max-width: 600px){
                margin-left: 245px;
                width: 130px;
                height: 40px;
                font-size: 18px;
            }
            @media screen and (max-width: 410px){
                margin-left: 171px;
                width: 130px;
                height: 40px;
                font-size: 18px;
            }
        }
    }
}

