
.container{
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    height: 100vh;
    width: 580px;
    margin:auto;
    background-color: black;
    @media (prefers-color-scheme: light) {
        background-color: white;
    }
    @media screen and (max-width: 925px){
        justify-content: flex-start;
    }
    @media (max-width: 600px) {
        height: auto;
        width: 378px;
        margin: auto;
    }
    @media (max-width: 410px) {
        width: 303px;

    }

    &__head {
        margin:20px;
        font-size: 4px;
        display: flex;
        flex-direction: row;

        @media (max-width: 600px) {
            width: 100%;
            padding: 10px 0 10px 0;
            margin: 5px;
            flex-direction: row;
        }
        &-title {
            display: flex;
            align-items: left;
            width: 580px;
        }
    }
    &__middle {
        display: flex;
        flex-direction: row;
       
        @media (max-width: 600px) {
            width: 100%;
            margin: 0 20px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
        @media (max-width: 410px) {
            margin: 0 10px;
            
        }
    }
    
    &__down {
        margin: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        @media (max-width: 600px) {
            flex-direction: column;
            margin: auto;
            margin-top: 10px;
        }
        
        &-main {
            width: 580px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            list-style-type: none;
            @media (max-width: 600px) {
                flex-direction: column;
                width: auto;
                gap:10px;
            }

            ul{
                list-style-type: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 71px;
                font-size: 19px;
                @media (max-width: 600px) {
                    flex-direction: row;
                    gap: 10px;
                    justify-content: space-around;
                    width: 100%;
                }
            }

            li {
                cursor: pointer;
            }

            li:hover {
                background-color: black;
                color: white; 
                @media (prefers-color-scheme: dark) {
                    background-color: white;
                    color: black;
                }
            }

            &-job{
                margin-top: -6px;
                font-size: 34px;
                @media screen and (max-width: 410px){
                    font-size: 26px;
                    margin-top: 0px;
                    
                }
            }
        }
    }
}