/* Styles for the modal overlay */
.ReactModal__Overlay {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    transform: scale(0.15);
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: scale(1);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: scale(0.15);
}

/* Styles for the modal content */
.modalcontent {
    position: relative; /* Positioned relative to the flexbox overlay */
    width: 78%;
    height: 580px;
    border: 1px solid #ccc;
    background: #ccc;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    opacity: 1; /* Initial opacity, consider if this should be 0 */
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    @media (prefers-color-scheme: dark) {
        background-color: #222;
        color: white;
    }
    @media (prefers-color-scheme: light) {
    background-color: #eee;
    color: black;
      }
    @media screen and (max-width: 925px){
        height: 90vh;
        width: 580px;
        padding: 0px;
        margin-top: 15px;
    }
    @media screen and (max-width: 600px){
        height: 90vh;
        width: 378px;
        padding: 0px;
        margin-top: 15px;
    }
    @media screen and (max-width: 410px){
        height: 85vh;
        width: 303px;
        padding: 0px;
        margin-top: 25px;
    }
    &__biocontact {
        width: 580px;
    
           
        @media screen and (max-width: 600px){
            width: 378px;
        }
        @media screen and (max-width: 410px){
            width: 303px;
        }
    }

    &__confirmation{
        width: 580px;
        @media screen and (max-width: 600px){
            width: 378px;
            
        }
        @media screen and (max-width: 410px){
            width: 303px;
            
        }
    }
    

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border: solid 1px #aaa;
        background: #333;
        cursor: pointer;
        font-size: 24px;
         font-weight: bold;
        line-height: 1;
        padding: 2px;
        color: #aaa;
        @media screen and (max-width: 600px){
            position: fixed;
            top: 20px;
            right: 22px;
            font-size: 20px;

        }
        &:hover {
            color: #f00;
        }
    }
    img {
        max-width: 460px;
        width: auto;
        height: auto;
        margin: 10px;
        box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
        object-fit: contain;
        border: 5px solid rgba(0,0,0,0.6);

        
        @media screen and (max-width: 600px){
            width: 100%;
            margin: 3px;
            box-shadow: 0px 10px 5px rgba(0,0,0,0.6);
            object-fit: contain;
            border: 5px solid rgba(0,0,0,0.6);
        }
  
    }
    p {
        margin: 10px 0;
        @media (max-width: 600px){
            margin: 5px;    
            width: 100%;        
        }
    }
}

/* Specificity to ensure it overrides any default styling */
.modal-content.open {
    opacity: 1; /* Fully visible when open */
}
