.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; // Space between each card
    @media  (max-width: 600px) {
        gap: 6px; // Reduced gap when modal is openyarn
    }
    @media  (max-width: 410px) {
        gap: 3px; // Reduced gap when modal is open
    }
    
    &__projects {
        flex: 1 1 calc(100% / 6); // Each card can grow and shrink, and its base size is 1/6 of the container width
         max-width: 80px; // Each card will not exceed 80px in width
        max-height: 80px;
        border-radius: 5px;
        box-shadow: 0 2px 2px rgba(0,0,0,0.3);
        transition: scale 0.2s ease;
        scale: 1; // Default margin
        @media (max-width: 600px) {
            flex: 1 0 calc(100%/ 6); /* Chaque card a une base de 1/6 de la largeur du viewport */
            width: 100%;
            height: auto;
            max-width: 58px;
            max-height: 58px;
            border-radius: 3px;
        }
        @media (max-width: 410px) {
            max-width: 48px;
            max-height: 48px;
            border-radius: 2px;
        }
        &-website:hover, &-myhead:hover {
            cursor: pointer;
            filter:hue-rotate(150deg);
        }

        img {
            opacity: 1; /* Hide the image initially */
            transition: 
            filter 0.2s ease-out; /* Smooth transition for the opacity change */
            border-radius: 5px;
            width: 100%; /* Make the image cover 100% of the card width */
            height: 100%; /* Make the image cover 100% of the card height */
            object-fit: cover; /* Ensure the image fills the entire card without distortion */
            @media (max-width: 600px) {
                border-radius: 3px;
            }
        }
    }

    &.cards__modal-open &__projects {
        scale: 0; // Reduced margin when modal is open
    }
}

.cards__letter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    color: white;
    height: 80px;
    @media (max-width: 600px) {
        font-size: 65px;
        height: 55px;
    }
    @media (max-width: 410px) {
        font-size: 60px;
        height: 45px;
    }
}


.cards li {
    background-color: gray;
    list-style-type: none;
    @media (prefers-color-scheme: light) {
        background-color: gray;
    }
    @media (prefers-color-scheme: dark) {
        background-color: gray;
    }
}

.hiddenCard {
    width: 80px;
    height: 80px;
    display: flex;            
    flex-direction: column;   
    justify-content: center;
    align-items: center;      

    @media (max-width: 600px) {
        display: none;
    }
}

.visible{
    background-color: white;
    color:white;
    transition: background-color 0.2s ease-in-out;

    @media (max-width: 600px) {
        display: none;
    }
}

.visible:hover {
    background-color: gray;

}

.hidden{
    background-color: white;
}

